import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { PageProps } from "gatsby"
import { ethers, BigNumber } from "ethers";
import Connect from "../../components/pages/zone/connect";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Toast from "../../components/toast";
import Zone from "../../components/pages/zone"
// import Habitats from "../../../abis/rinkeby/Habitats.json";
import Habitats from "../../../abis/mainnet/Habitats.json";

const MAINNET_NETWORK_ID = 1;

const ZonePage: React.FC<PageProps> = pageProps => {
  const [account, setAccount] = useState<string | null>(null);
  const [contract, setContract] = useState<any | null>(null);
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [tokenIds, setTokenIds] = useState<Array<number>>([]);

  const getAndSetAccount = async () => {
    if (typeof window !== 'undefined' && window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum || 'http://127.0.0.1:8545');
      const addresses = await provider.listAccounts();
      const address = addresses[0] as string;
      setAccount(address ? ethers.utils.getAddress(address) : address);
    }
  };

  useEffect(() => {
    const setupWeb3Info = async () => {
      if (typeof window !== 'undefined' && window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum || 'http://127.0.0.1:8545');

        const { chainId } = await provider.getNetwork();
        if (chainId !== MAINNET_NETWORK_ID) {
          setShouldShowError(true);
        }

        const networkData = Habitats.networks[chainId];
        if (networkData) {
          const abi = Habitats.abi;
          const contractAddress = networkData.address;
          const contract = new ethers.Contract(contractAddress, abi, provider.getSigner(account ? account : undefined));
          setContract(contract);
        }

        getAndSetAccount();
      }
    }

    setupWeb3Info()
  }, [account]);

  // Sets contract state
  useEffect(() => {
    if (contract && account) {
      contract.tokensOfOwner(account).then((ids: BigNumber[]) => {
        const numberIds = ids.map(id => id.toNumber());
        if (numberIds.length > 0){
          setTokenIds(numberIds);
        }
      });
    }
  }, [contract, account]);

  return (
    <Layout pageProps={pageProps}>
      <SEO title="Habitats | Zone" />
      <Helmet>
        <script type="module" src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"></script>
      </Helmet>
      {shouldShowError && (
        <Toast
          text="You're not connected to the Ethereum mainnet. You must be connected mainnet to view your Habitats."
          variant='error'
          onClose={() => {
            setShouldShowError(false);
          }}
        />
      )}
      {tokenIds && tokenIds.length > 0
        ?
        <Zone
          contract={contract}
          accountAddress={account}
          getAndSetAccount={getAndSetAccount}
          tokenIds={tokenIds}
        />
        :
        <Connect
          headerText="No Access"
          bodyText="In order to view the Habitat Zone you must own at least one Habitat."
        />
      }
    </Layout>
  )
}

export default ZonePage
