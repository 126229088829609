import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const styles = require('./toggleSwitch.module.scss');

type ToggleSwitchProps = {
  toggleName: string,
  leftValue: string,
  middleValue: string,
  rightValue: string,
  selectedValue?: string,
  onToggle: (value: string) => void,
  color?: 'green' | 'blue' | 'pink' | 'orange',
  customTextClassName?: string,
  customBtnClassName?: string,
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = (props: ToggleSwitchProps) => {
  const {
    toggleName = 'toggleSwitch',
    leftValue,
    middleValue,
    rightValue,
    selectedValue,
    onToggle,
    color,
    customTextClassName = '',
    customBtnClassName = '',
  } = props;

  // TODO: make dynamic with base colors
  let btnColor: string;
  switch (color) {
    case 'orange':
      btnColor = styles.btnColorOrange;
      break;
    default:
      btnColor = styles.btnColorOrange;
  }

  return (
    <>
      <Container className={styles.toggleContainer}>
        <Row className={styles.toggleRowContainer}>
          <Col className="d-flex justify-content-center">
            <input
              id={`${toggleName}-toggle-on`}
              className={`${styles.toggle} ${styles.toggleLeft}`}
              name={toggleName}
              value={leftValue}
              type='radio'
              checked={leftValue === selectedValue}
              onChange={() => onToggle(leftValue)}
            />
            <label htmlFor={`${toggleName}-toggle-on`} className={`${styles.btnLeft} ${btnColor} ${customBtnClassName}`}>
              <div className={styles.valueTextContainer}>
                <span className={`${styles.valuesText} ${customTextClassName}`}>
                  {leftValue}
                </span>
              </div>
            </label>

            <input
              id={`${toggleName}-toggle-off`}
              className={`${styles.toggle} ${styles.toggleMiddle}`}
              name={toggleName}
              value={middleValue}
              type='radio'
              onChange={() => onToggle(middleValue)}
            />
            <label htmlFor={`${toggleName}-toggle-off`} className={`${styles.btnMiddle} ${btnColor} ${customBtnClassName}`}>
              <div className={styles.valueTextContainer}>
                <span className={`${styles.valuesText} ${customTextClassName}`}>
                  {middleValue}
                </span>
              </div>
            </label>

            <input
              id={`${toggleName}-toggle-off-1`}
              className={`${styles.toggle} ${styles.toggleRight}`}
              name={toggleName}
              value={rightValue}
              type='radio'
              onChange={() => onToggle(rightValue)}
            />
            <label htmlFor={`${toggleName}-toggle-off-1`} className={`${styles.btnRight} ${btnColor} ${customBtnClassName}`}>
              <div className={styles.valueTextContainer}>
                <span className={`${styles.valuesText} ${customTextClassName}`}>
                  {rightValue}
                </span>
              </div>
            </label>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ToggleSwitch;
