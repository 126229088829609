import React, { useEffect, useState } from "react";
import axios from "axios";
import { Contract } from "ethers";
import { Container, Row, Col, Image, DropdownButton, Dropdown, Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdClose, MdOutlineKeyboardArrowDown } from "react-icons/md";
import SamplePavilion from "../../../images/samplePavilion.png";
import JSZip from "jszip";
import FileSaver from "file-saver";
import ToggleSwitch from "../../toggleSwitch";

const styles = require("./zone.module.scss");
const tokenIdsToAssetsMap = require("./mapping.json");

type ZoneProps = {
  accountAddress: string | null,
  getAndSetAccount: Function,
  contract: Contract,
  tokenIds: Array<number>,
}

type MetadataAttribute = {
  trait_type: string,
  value: string | number,
}

const Zone: React.FC<ZoneProps> = ({ accountAddress, getAndSetAccount, contract, tokenIds }) => {

  // Toggle Inventory Header
  const [showHabitats, setShowHabitats] = useState<boolean>(true);
  const [shouldShowUnity, setShouldShowUnity] = useState<boolean>(false);
  const [selectedHabitatTypeToggle, setSelectedHabitatTypeToggle] = useState<string>('ORIGINAL');
  
  // Toggle Displayed Image
  const [selectedHabitat, setSelectedHabitat] = useState<number>(tokenIds[0]);
  const [shouldEnlargeImage, setShouldEnlargeImage] = useState<boolean>(false);

  // Owner state
  const [tokenIdsToCardImageUrls, setTokenIdsToCardImageUrls] = useState<Map<number, string>>(new Map());
  const [tokenIdsToXlCardImageUrls, setTokenIdsToXlCardImageUrls] = useState<Map<number, string>>(new Map());
  const [tokenIdsToTowerCardImageUrls, setTokenIdsToTowerCardImageUrls] = useState<Map<number, string>>(new Map());
  const [tokenIdsToAnimationUrls, setTokenIdsToAnimationUrls] = useState<Map<number, string>>(new Map());
  const [tokenIdsToXlAnimationUrls, setTokenIdsToXlAnimationUrls] = useState<Map<number, string>>(new Map());
  const [tokenIdsToTowerAnimationUrls, setTokenIdsToTowerAnimationUrls] = useState<Map<number, string>>(new Map());
  const [tokenIdsToMetadata, setTokenIdsToMetadata] = useState<Map<number, MetadataAttribute[]>>(new Map());

  const setHabitatInfo = (async () => {
    const updatedTokenIdsToCardImageUrls = new Map();
    const updatedTokenIdsToXlCardImageUrls = new Map();
    const updatedTokenIdsToTowerCardImageUrls = new Map();
    const updatedTokenIdsToAnimationUrls = new Map();
    const updatedTokenIdsToXlAnimationUrls = new Map();
    const updatedTokenIdsToTowerAnimationUrls = new Map();
    const updatedTokenIdsToMetadata = new Map();
    for (let i = 0; i < tokenIds.length; i++) {
      const internalTokenId = tokenIds[i];
      const assetId = parseInt(tokenIdsToAssetsMap[internalTokenId]);

      // Set metadata
      const response = await axios.get(`https://d1ioil6msjz3h1.cloudfront.net/metadata/${internalTokenId}`);
      updatedTokenIdsToMetadata.set(internalTokenId, response.data.attributes);

      // Set thumbnails
      const imageUrl = "https://d1ioil6msjz3h1.cloudfront.net/thumbnails_compressed/" + assetId + ".jpg";
      updatedTokenIdsToCardImageUrls.set(internalTokenId, imageUrl);

      // Set XL thumbnails
      const xlImageUrl = "https://d1ioil6msjz3h1.cloudfront.net/XL_Thumbnails/" + assetId + ".jpg";
      updatedTokenIdsToXlCardImageUrls.set(internalTokenId, xlImageUrl);

      // Set tower thumbnails
      const towerImageUrl = "https://d1ioil6msjz3h1.cloudfront.net/towers_thumbnails/" + assetId + ".jpg";
      updatedTokenIdsToTowerCardImageUrls.set(internalTokenId, towerImageUrl);

      // Set .glb files
      const animationUrl = "https://d1ioil6msjz3h1.cloudfront.net/glb_final/" + assetId + ".glb";
      updatedTokenIdsToAnimationUrls.set(internalTokenId, animationUrl);

      // Set XL .glb files
      const xlAnimationUrl = "https://d1ioil6msjz3h1.cloudfront.net/XL_2sided/" + assetId + ".glb";
      updatedTokenIdsToXlAnimationUrls.set(internalTokenId, xlAnimationUrl);

      // Set tower .glb files
      const towerAnimationUrl = "https://d1ioil6msjz3h1.cloudfront.net/towers_2sided/" + assetId + ".glb";
      updatedTokenIdsToTowerAnimationUrls.set(internalTokenId, towerAnimationUrl);
    }

    setTokenIdsToCardImageUrls(updatedTokenIdsToCardImageUrls);
    setTokenIdsToXlCardImageUrls(updatedTokenIdsToXlCardImageUrls);
    setTokenIdsToTowerCardImageUrls(updatedTokenIdsToTowerCardImageUrls);
    setTokenIdsToAnimationUrls(updatedTokenIdsToAnimationUrls);
    setTokenIdsToXlAnimationUrls(updatedTokenIdsToXlAnimationUrls);
    setTokenIdsToTowerAnimationUrls(updatedTokenIdsToTowerAnimationUrls);
    setTokenIdsToMetadata(updatedTokenIdsToMetadata);
  });

  useEffect(() => {
    if (tokenIds && tokenIds.length > 0) {
      setHabitatInfo();
    }
  }, []);

  const renderedHabitats = tokenIds.map((tokenId) => {
    const imageUrl =
      selectedHabitatTypeToggle === "XL"
        ? tokenIdsToXlCardImageUrls.get(tokenId)
        : selectedHabitatTypeToggle === "TOWER"
          ? tokenIdsToTowerCardImageUrls.get(tokenId)
          : tokenIdsToCardImageUrls.get(tokenId);
    const cardContent = 
      <Image 
        className={`${selectedHabitat === tokenId ? styles.habitatImageActive : styles.habitatImage} ${selectedHabitatTypeToggle === "TOWER" ? styles.habitatTowerImage : ''}`}
        src={imageUrl ? imageUrl : SamplePavilion} // TODO: Render placeholder image if the  image isn't loaded
      />;

    return (
      <Col className={`col-6 col-sm-6 col-md-4 col-lg-3 ${styles.habitatColumn}`} key={`habitat-${tokenId}`}>
        <div
          className={styles.habitatCard}
          key={tokenId}
          onClick={() => {
            if (selectedHabitat !== tokenId) {
              setSelectedHabitat(tokenId);
            }
          }}
        >
          {cardContent}
        </div>
      </Col>
    );
  });

  return (
    <>
      {shouldEnlargeImage && (
        <div
          className={styles.enlargedModelViewerContainer}
          onClick={() => {
            setShouldEnlargeImage(false);
          }}
        >
          <MdClose
            className={styles.enlargedModelCloseButton}
            size={25}
            color="white"
            onClick={() => setShouldEnlargeImage(false)}
          />
          <div className={styles.modelViewerBackground}>
            <model-viewer
              className={styles.renderedEnlargedHabitat}
              src={
                selectedHabitatTypeToggle === "XL"
                  ? tokenIdsToXlAnimationUrls.get(selectedHabitat)
                  : selectedHabitatTypeToggle === "TOWER"
                    ? tokenIdsToTowerAnimationUrls.get(selectedHabitat)
                    : tokenIdsToAnimationUrls.get(selectedHabitat)
              }
              enable-pan
              auto-rotate
              shadow-intensity="1"
              ar=""
              ar-modes="webxr scene-viewer quick-look"
              camera-controls=""
              alt="A 3D model carousel"
              data-js-focus-visible=""
              ar-status="not-presenting"
              style={{
                width: "100%",
                height: "100%",
                margin: "0px",
                borderRadius: "50px"
              }}
              onClick={(e) => {
                e.stopPropagation();
                setShouldEnlargeImage(true);
              }}
            ></model-viewer>
          </div>
        </div>
      )}

      <Container className={styles.contentContainer}>
        <Row className="d-flex justify-content-center">
          <Col className={`col-12 col-md-9 col-lg-8 ${styles.introContainer}`}>
            <p className={styles.headerContainer}>
              <span className={styles.introHeader}>Habitat Zone</span>
            </p>
            <p className={styles.introBody}>
              We have a few fun things for you in here. Go explore!
            </p>
          </Col>
        </Row>
      </Container>
      <Container className={`${styles.renderedHabitatContainer}`} fluid>
        <Row className={styles.content}>
          <Col className="col-12 col-md-12 col-lg-4" style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Row className={`${styles.metadataContainer} g-1`}>
              {tokenIdsToMetadata.get(selectedHabitat)?.map((attribute, index) => {
                return (
                  <Col key={`metadata-${index}`} style={{ minWidth: "10.5rem", maxWidth: "10.5rem" }}>
                    <Card key={`${attribute.trait_type}-${index}`} style={{ width: '10rem' }}>
                      <Card.Body>
                        <Card.Title className={styles.habitatMetadataType}>{attribute.trait_type}</Card.Title>
                        <Card.Text className={styles.habitatMetadataAttribute}>
                        {attribute.value}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Col>
          <Col className="col-12 col-sm-10 col-md-6 col-lg-4">
            <model-viewer
              className={styles.renderedHabitat}
              src={
                selectedHabitatTypeToggle === "XL"
                  ? tokenIdsToXlAnimationUrls.get(selectedHabitat)
                  : selectedHabitatTypeToggle === "TOWER"
                    ? tokenIdsToTowerAnimationUrls.get(selectedHabitat)
                    : tokenIdsToAnimationUrls.get(selectedHabitat)
              }
              enable-pan
              auto-rotate
              shadow-intensity="1"
              ar=""
              ar-modes="webxr scene-viewer quick-look"
              camera-controls=""
              alt="A 3D model carousel"
              data-js-focus-visible=""
              ar-status="not-presenting"
            ></model-viewer>
            <div className="d-flex justify-content-center">
              <Button
                variant="outline-primary"
                className={styles.fullScreenButton}
                style={{ width: "100%" }}
                onClick={() => setShouldEnlargeImage(true)}
              >
                FULL SCREEN
              </Button>
            </div>
          </Col>
          <Col className="col-12 col-md-12 col-lg-3">
            <Row style={{ width: "100%" }}>
              <Col className="d-flex justify-content-center col-12">
                {selectedHabitatTypeToggle === "XL"
                  ? <p className={styles.titleText}>XL Habitat #{selectedHabitat}</p>
                  : selectedHabitatTypeToggle === "TOWER"
                    ? <p className={styles.titleText}>Tower Habitat #{selectedHabitat}</p>
                    : <p className={styles.titleText}>Habitat #{selectedHabitat}</p>
                }
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col className="d-flex justify-content-center col-12">
                <ToggleSwitch
                  toggleName='type-toggle'
                  leftValue='ORIGINAL'
                  middleValue='XL'
                  rightValue='TOWER'
                  selectedValue={selectedHabitatTypeToggle}
                  onToggle={(value: string) => setSelectedHabitatTypeToggle(value)}
                />
              </Col>
            </Row>
            <Row style={{ width: "100%" }}>
              <Col className="d-flex justify-content-center col-12">
                {selectedHabitatTypeToggle === "XL"
                  ?
                  <DropdownButton bsPrefix={styles.downloadButton} title={<span>DOWNLOAD <MdOutlineKeyboardArrowDown className={styles.downloadIcon} color="white" size={30} /></span>}>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_2sided/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                      target="_blank"
                    >
                      Spatial.io
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_2sided_scaled/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                      target="_blank"
                    >
                      OnCyber
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      onClick={(e) => {
                        e.preventDefault();
                        const zip = new JSZip();
                        const mtlBlobPromise = fetch("https://d1ioil6msjz3h1.cloudfront.net/XL_glb/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb").then(r => {
                          if (r.status === 200) return r.blob()
                          return Promise.reject(new Error(r.statusText))
                        })
                        zip.file(tokenIdsToAssetsMap[selectedHabitat] + ".glb", mtlBlobPromise)

                        const objBlobPromise = fetch("https://d1ioil6msjz3h1.cloudfront.net/XL_collider/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb").then(r => {
                          if (r.status === 200) return r.blob()
                          return Promise.reject(new Error(r.statusText))
                        })
                        zip.file(tokenIdsToAssetsMap[selectedHabitat] + "_collider.glb", objBlobPromise)

                        zip.generateAsync({type:"blob"})
                            .then(blob => FileSaver.saveAs(blob, "habitat-decentraland-files.zip"))
                            .catch(e => console.log(e));
                      }}
                    >
                      Decentraland
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                      target="_blank"
                    >
                      Monaverse
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_2sided/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                      target="_blank"
                    >
                      Mozilla Hubs
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                      target="_blank"
                    >
                      Somnium Space
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                      target="_blank"
                    >
                      Webaverse
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                      target="_blank"
                    >
                      MetaMetaVerse
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                      target="_blank"
                    >
                      Unity Engine
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                      target="_blank"
                    >
                      Unreal Engine
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.downloadLink}
                      href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                      target="_blank"
                    >
                      Twinmotion
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <OverlayTrigger
                      key="glb-tooltip"
                      placement={"left"}
                      overlay={
                        <Tooltip>
                          <span className={styles.tooltipText}>Main Habitat 3D Model</span>
                        </Tooltip>
                      }
                    >
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/XL_2sided/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                        target="_blank"
                        download="test.glb"
                      >
                        General .glb
                      </Dropdown.Item>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="fbx-tooltip"
                      placement={"left"}
                      overlay={
                        <Tooltip>
                          <span className={styles.tooltipText}>Main Habitat 3D Model</span>
                        </Tooltip>
                      }
                    >
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/XL_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                        download
                      >
                        General .fbx
                      </Dropdown.Item>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="thumbnail-tooltip"
                      placement={"left"}
                      overlay={
                        <Tooltip>
                          <span className={styles.tooltipText}>3D High Fidelity Rendering</span>
                        </Tooltip>
                      }
                    >
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/XL_Thumbnails/" + tokenIdsToAssetsMap[selectedHabitat] + ".jpg"}
                        target="_blank"
                        download
                      >
                        XL Thumbnail
                      </Dropdown.Item>
                    </OverlayTrigger>
                  </DropdownButton>
                  : selectedHabitatTypeToggle === "TOWER"
                    ?
                    <DropdownButton bsPrefix={styles.downloadButton} title={<span>DOWNLOAD <MdOutlineKeyboardArrowDown className={styles.downloadIcon} color="white" size={30} /></span>}>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_2sided/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                        target="_blank"
                      >
                        Spatial.io
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_2sided_scaled/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                        target="_blank"
                      >
                        OnCyber
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        onClick={(e) => {
                          e.preventDefault();
                          const zip = new JSZip();
                          const mtlBlobPromise = fetch("https://d1ioil6msjz3h1.cloudfront.net/towers_glb/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb").then(r => {
                            if (r.status === 200) return r.blob()
                            return Promise.reject(new Error(r.statusText))
                          })
                          zip.file(tokenIdsToAssetsMap[selectedHabitat] + ".glb", mtlBlobPromise)

                          const objBlobPromise = fetch("https://d1ioil6msjz3h1.cloudfront.net/towers_colliders/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb").then(r => {
                            if (r.status === 200) return r.blob()
                            return Promise.reject(new Error(r.statusText))
                          })
                          zip.file(tokenIdsToAssetsMap[selectedHabitat] + "_collider.glb", objBlobPromise)

                          zip.generateAsync({type:"blob"})
                              .then(blob => FileSaver.saveAs(blob, "habitat-decentraland-files.zip"))
                              .catch(e => console.log(e));
                        }}
                      >
                        Decentraland
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Monaverse
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_2sided/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                        target="_blank"
                      >
                        Mozilla Hubs
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Somnium Space
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Webaverse
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        MetaMetaVerse
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Unity Engine
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Unreal Engine
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Twinmotion
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <OverlayTrigger
                        key="glb-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>Main Habitat 3D Model</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/towers_2sided/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                          target="_blank"
                          download="test.glb"
                        >
                          General .glb
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        key="fbx-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>Main Habitat 3D Model</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/towers_fbx/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                          target="_blank"
                          download
                        >
                          General .fbx
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        key="thumbnail-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>3D High Fidelity Rendering</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/towers_thumbnails/" + tokenIdsToAssetsMap[selectedHabitat] + ".jpg"}
                          target="_blank"
                          download
                        >
                          XL Thumbnail
                        </Dropdown.Item>
                      </OverlayTrigger>
                    </DropdownButton>
                    :
                    <DropdownButton bsPrefix={styles.downloadButton} title={<span>DOWNLOAD <MdOutlineKeyboardArrowDown className={styles.downloadIcon} color="white" size={30} /></span>}>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/glb_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                        target="_blank"
                      >
                        Spatial.io
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/scaled_glb/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                        target="_blank"
                      >
                        OnCyber
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        onClick={(e) => {
                          e.preventDefault();
                          const zip = new JSZip();
                          const mtlBlobPromise = fetch("https://d1ioil6msjz3h1.cloudfront.net/glb_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb").then(r => {
                            if (r.status === 200) return r.blob()
                            return Promise.reject(new Error(r.statusText))
                          })
                          zip.file(tokenIdsToAssetsMap[selectedHabitat] + ".glb", mtlBlobPromise)

                          const objBlobPromise = fetch("https://d1ioil6msjz3h1.cloudfront.net/colliders/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb").then(r => {
                            if (r.status === 200) return r.blob()
                            return Promise.reject(new Error(r.statusText))
                          })
                          zip.file(tokenIdsToAssetsMap[selectedHabitat] + "_collider.glb", objBlobPromise)

                          zip.generateAsync({type:"blob"})
                              .then(blob => FileSaver.saveAs(blob, "habitat-decentraland-files.zip"))
                              .catch(e => console.log(e));
                        }}
                      >
                        Decentraland
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Monaverse
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/glb_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                        target="_blank"
                      >
                        Mozilla Hubs
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Somnium Space
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Webaverse
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        MetaMetaVerse
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Unity Engine
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Unreal Engine
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.downloadLink}
                        href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                        target="_blank"
                      >
                        Twinmotion
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <OverlayTrigger
                        key="glb-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>Main Habitat 3D Model</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/glb_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".glb"}
                          target="_blank"
                          download="test.glb"
                        >
                          General .glb
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        key="fbx-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>Main Habitat 3D Model</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/fbx_final/" + tokenIdsToAssetsMap[selectedHabitat] + ".fbx"}
                          target="_blank"
                          download
                        >
                          General .fbx
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        key="3d-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>.vrml File</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/vrml/" + tokenIdsToAssetsMap[selectedHabitat] + ".vrml"}
                          target="_blank"
                          download
                        >
                          3D Print File
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        key="3dm-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>Rhinoceros 3D Editing File</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/3dm/" + tokenIdsToAssetsMap[selectedHabitat] + ".3dm"}
                          target="_blank"
                          download
                        >
                          Rhinoceros 3D
                        </Dropdown.Item>
                      </OverlayTrigger>
                      <OverlayTrigger
                        key="thumbnail-tooltip"
                        placement={"left"}
                        overlay={
                          <Tooltip>
                            <span className={styles.tooltipText}>3D High Fidelity Rendering</span>
                          </Tooltip>
                        }
                      >
                        <Dropdown.Item
                          className={styles.downloadLink}
                          href={"https://d1ioil6msjz3h1.cloudfront.net/thumbnails_compressed/" + tokenIdsToAssetsMap[selectedHabitat] + ".jpg"}
                          target="_blank"
                          download
                        >
                          Thumbnail
                        </Dropdown.Item>
                      </OverlayTrigger>
                    </DropdownButton>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className={styles.inventoryContainer}>
        <Row className="d-flex justify-content-center">
          <Col className={`col-12 col-md-9 col-lg-8 ${styles.headerContainer}`}>
            <button
              className={styles.headerButton}
              onClick={() => {
                  setShowHabitats(true);
                  setShouldShowUnity(false);
                }}
            >
              <span className={showHabitats ? styles.activeSelectHeader : styles.selectHeader}>My Habitats</span>
            </button>
            <button
              className={styles.headerButton}
              onClick={() => {
                setShowHabitats(false);
                setShouldShowUnity(true);
              }}
            >
              <span className={shouldShowUnity ? styles.activeSelectHeader : styles.selectHeader}>Unity</span>
            </button>
          </Col>
        </Row>
        <Row className={`d-flex align-items-center justify-content-center  ${styles.itemContainer}`}>
          <Col className={`col-12 col-md-9 col-lg-8`}> 
            <Row>
              {
                showHabitats
                  ? renderedHabitats
                  :
                  <Col>
                    <p className={styles.unityPackageText}><span className={styles.highlightedText}>The Habitats Unity Package</span> is a general use utility to help habitats builders make the most of their builds in Unity and all Unity based virtual worlds.</p>
                    <ol>
                      <li className={styles.unityPackageText}>Download the .unitypackage below</li>
                      <li className={styles.unityPackageText}>In your open Unity projects, navigate to <span className={styles.highlightedText}>Assets > Import > Custom Package</span></li>
                      <li className={styles.unityPackageText}>Select the Habitats_v1.unitypackage and import all assets.</li>
                    </ol>
                    <p className={styles.unityPackageText}>This will bring in a root “Habitats” folder in your Unity project with some sub-folders and editor scripts.</p>
                    <p className={styles.unityPackageText}>Please watch this tutorial for additional guidance: <a className={styles.highlightedText} href="https://vimeo.com/729410450" target="_blank">https://vimeo.com/729410450</a></p>
                    <a
                      href="https://d1ioil6msjz3h1.cloudfront.net/Habitats_UnityPackage_v1.unitypackage"
                      target="_blank"
                    >
                      <Button
                        variant="outline-primary"
                        className={styles.unityPackageButton}
                        style={{ width: "100%" }}
                      >
                        Download Unity Package
                      </Button>
                    </a>
                  </Col>
              }
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Zone
